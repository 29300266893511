<template>
  <div class="Bidding_wrap">
    <div class="Bidding_liAction">
      <div
        class="Bidding_liAction_item"
        v-for="item in LiData"
        :key="item.code"
        style="cursor: pointer"
        @click="setType(item)"
        :style="item.code == activeType ? 'background:#0074FF;color:#fff' : ''"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { PurchasingTableStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const LiData = [
      {
        title: "采购公告",
        code: "tender_bulletin",
      },
      {
        title: "变更公告 ",
        code: "correctional_bulletin",
      },
      {
        title: "答疑澄清",
        code: "clarify",
      },
      {
        title: "中标公告",
        code: "win_notice",
      },
      {
        title: "终止公告",
        code: "failed_notice",
      },
      {
        title: "单一来源公示",
        code: "single_publicity",
      },
    ];
    const setType = (item) => {
      PurchasingTableStore().setData("type", item.code);
      PurchasingTableStore().reloadTableData()
    };
    const activeType = computed(() => storeToRefs(PurchasingTableStore()).type);
    const data = reactive({
      LiData,
      activeType,
    });

    return {
      ...data,
      setType,
    };
  },
};
</script>

<style scoped>
@import url("./LiAction.css");
</style>

