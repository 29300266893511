<template>
  <div class="SearchFormType">
    <div
      class="SearchFormType_wrap"
      v-for="(SearchCodeItem, index) in 1"
      :key="SearchCodeItem"
    >
      <p
        style="white-space: nowrap; margin: 0 20px"
        :style="index !== 0 ? 'visibility: hidden' : ''"
      >
        项目分类：
      </p>
      <div
        class="SearchFormType_item"
        v-for="item in SearchCode"
        :key="item.id"
        style="cursor: pointer"
        @click="setTradeClassification(item)"
        :style="
          item.dictKey == 'all' && tradeClassification == ''
            ? 'background:#F46C6C;color:#fff'
            : item.dictKey == tradeClassification
            ? 'background:#F46C6C;color:#fff'
            : ''
        "
      >
        {{ item.dictValue }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { storeToRefs } from "pinia";
import { PurchasingTableStore } from "@/store";
import _ from "lodash";
export default {
  setup() {
    const SearchCode = [
      {
        dictValue: "全部",
        dictKey: '',
      },
      {
        dictValue: "工程",
        dictKey: "A",
      },
      {
        dictValue: "货物",
        dictKey: "B",
      },
      {
        dictValue: "服务",
        dictKey: "C",
      },
    ];
    // const SearchCode = computed(() => {
    //   return storeToRefs(PurchasingTableStore()).projectTypeData;
    // });
    const setTradeClassification = (item) => {
      PurchasingTableStore().setData(
        "tradeClassification",
        item.dictKey
      );
      PurchasingTableStore().reloadTableData();
    };
    const tradeClassification = computed(() => {
      return storeToRefs(PurchasingTableStore())
        .tradeClassification;
    });
    // dictKey
    const data = reactive({
      SearchCode,
      tradeClassification,
    });
    return {
      ...data,
      setTradeClassification,
    };
  },
};
</script>

<style scoped>
@import url("./SearchFormType.css");
</style>

