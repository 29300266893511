<template>
  <div class="SearchFormSort">
    <div class="SearchFormSort_wrap">
      <div class="SearchFormSort_left">
        <p style="margin: 0 20px">排序方式：</p>
        <div
          class="SearchFormSort_item"
          v-for="item in SearchCode"
          :key="item.code"
          style="cursor: pointer"
          :style="
            item.code == activeDescs && item.type == 'descs'
              ? 'background:#F46C6C;color:#fff'
              : item.code == activeAscs && item.type == 'ascs'
              ? 'background:#F46C6C;color:#fff'
              : ''
          "
          @click="setSort(item)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="SearchFormSort_right">
        <el-input
          style="color: red"
          v-model="searchKey"
          class="input_class"
          placeholder="请输入公告名称"
        >
          <template #append>
            <el-button type="primary" class="input_btn_class" @click="setName">
              查询
            </el-button>
          </template>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { PurchasingTableStore } from "@/store";
import { storeToRefs } from "pinia";
export default {
  setup() {
    const searchKey = ref("");
    const SearchCode = [
      {
        title: "发布时间降序",
        code: "publish_time",
        type: "descs",
      },
      {
        title: "发布时间升序",
        code: "publish_time",
        type: "ascs",
      },
      {
        title: "截止时间降序",
        code: "end_time",
        type: "descs",
      },
      {
        title: "截止时间升序",
        code: "end_time",
        type: "ascs",
      },
      // {
      //   title: "总访问降序",
      //   code: "fangwen",
      // },
    ];
    const activeDescs = computed(
      () => storeToRefs(PurchasingTableStore()).descs
    );
    const activeAscs = computed(() => storeToRefs(PurchasingTableStore()).ascs);
    const setSort = (item) => {
      if (item.type == "descs") {
        PurchasingTableStore().setData("descs", item.code);
        PurchasingTableStore().setData("ascs", "");
      } else if (item.type == "ascs") {
        PurchasingTableStore().setData("descs", "");
        PurchasingTableStore().setData("ascs", item.code);
      }
      PurchasingTableStore().reloadTableData();
    };
    const setName = () => {
      PurchasingTableStore().setData("name", searchKey.value);
      PurchasingTableStore().reloadTableData();
    };
    const data = reactive({
      SearchCode,
      activeDescs,
      activeAscs,
    });

    return {
      ...data,
      setSort,
      searchKey,
      setName,
    };
  },
};
</script>

<style scoped>
@import url("./SearchFormSort.css");
</style>

